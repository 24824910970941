p-radiobutton, p-tableradiobutton {
  .p-radiobutton {
    display: flex;
    align-items: center;
    .p-radiobutton-box {
      background: url('../../../assets/radio-button-default.svg');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;

      &.p-highlight {
        background: url('../../../assets/radio-button-highlight.svg');
      }

      .p-radiobutton-icon {
        display: none;
      }
    }
  }
}

