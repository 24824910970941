.ng-select {
  padding-bottom: 0;
  margin-bottom: 20px;
}

.owner-select .ng-select{
  min-height: 37px;
}

.owner-select .ng-select .ng-select-container.ng-appearance-outline {
  min-height: 37px;
}

.ng-select .ng-select-container {
  overflow: visible !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top: 0.485em solid transparent;
}

.ng-select .ng-select-container.ng-appearance-outline {
  min-height: 51px;
}

.ng-select .ng-select-container.ng-appearance-outline:after {
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  top: 0;
  height: auto;
}

.ng-select.ng-select-multiple
  .ng-select-container.ng-appearance-outline
  .ng-arrow-wrapper {
  border-top: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 20px;
}

.owner-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 25%;
}

.ng-select
  .ng-has-value.ng-select-container
  .ng-value-container
  .ng-placeholder,
.ng-select.ng-select-opened
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 16px;
}

.ng-select.ng-select-focused
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 16px;
  color: var(--theme-primary);
  left: 13px;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: var(--theme-primary);
}

.ng-select .ng-select-container.ng-appearance-outline:hover:after {
  border-width: 1px;
}

.ng-select.ng-select-disabled
  .ng-select-container.ng-appearance-outline:hover:after {
  border-color: rgba(0, 0, 0, 0.38);
  opacity: 0.3;
}

.ng-select.ng-select-disabled .ng-select-container.ng-appearance-outline:after {
  background-size: 0;
  opacity: 0.3;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused
  .ng-select-container.ng-appearance-outline:hover:after {
  border-color: var(--theme-primary);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background: rgba(121, 148, 149, 0.2);
  color: var(--theme-primary);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon {
  color: var(--theme-primary);
}

lha-multiselect,
lha-single-select {
  & ~ .mat-mdc-form-field-error {
    position: absolute;
    bottom: 0;
    left: 10px;
  }

  &.ng-invalid:not(.ng-untouched) {
    .ng-select .ng-select-container:after {
      border-color: var(--theme-warn);
    }

    .ng-select.ng-select-focused
      .ng-select-container
      .ng-value-container
      .ng-placeholder {
      color: var(--theme-warn);
    }

    .ng-select.ng-select-focused
      .ng-select-container
      .ng-arrow-wrapper
      .ng-arrow {
      color: var(--theme-warn);
    }

    .ng-select.ng-select-focused
      .ng-select-container.ng-appearance-outline:after,
    .ng-select.ng-select-focused
      .ng-select-container.ng-appearance-outline:hover:after {
      border-color: var(--theme-warn);
    }

    .ng-select .ng-select-container .ng-value-container .ng-placeholder {
      color: var(--theme-warn);
    }
  }
}

.form__box {
  position: relative;
}

.flex-1 {
  flex: 1;
  overflow: hidden;
}

lha-single-select.ng-invalid.ng-touched .ng-select {
  margin-bottom: -1px;
}
lha-single-select {
  position: relative;
  width: 100%;
  display: block;
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    left: 13px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }
  .ng-select .ng-select-container .ng-value-container {
    border-top: 0.84375em solid transparent;
  }
  .ng-select .ng-select-container.ng-appearance-outline {
    padding: 0 12px;
  }
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input
    input {
    font-size: 16px;
  }
  & ~ div {
    padding-left: 12px;
  }
  & + .ng-star-inserted {
    padding: 0 16px !important;
  }
  .ng-select .ng-select-container .ng-value-container .ng-input {
    top: 52%;
    padding: 0 15px;
    transform: translateY(-50%);
  }
}

lha-single-select + div mat-error {
  animation: validationError 0.28s ease-out;
}

@keyframes validationError {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.ng-select .ng-spinner-loader {
  top: 10px;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
.ng-select.ng-select-multiple.ng-select-disabled
  .ng-appearance-outline:hover:after {
  border: 1px solid rgba(0, 0, 0, 0.38);
}
.asset-mobile-well {
  .ng-select .ng-select-container.ng-appearance-outline {
    height: 37px;
    min-height: auto;
  }
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    top: 12px;
  }
  .ng-select
    .ng-has-value
    .ng-select-container
    .ng-value-container
    .ng-placeholder,
  .ng-select.ng-select-opened
    .ng-select-container
    .ng-value-container
    .ng-placeholder,
  .ng-select
    .ng-has-value.ng-select-container
    .ng-value-container
    .ng-placeholder {
    top: 16px;
  }
  .ng-select.ng-select-single
    .ng-select-container.ng-appearance-outline
    .ng-arrow-wrapper {
    bottom: 13px;
  }
  .ng-select.ng-select-single
    .ng-select-container.ng-appearance-outline
    .ng-clear-wrapper {
    bottom: 10px;
  }
  lha-single-select .ng-select .ng-select-container .ng-value-container {
    border-top: 0.3em solid transparent;
  }
}
