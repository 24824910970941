.vertical-tab {
  display: flex;
  margin-bottom: var(--lg-space);

  .p-tabview-nav-container {
    min-width: 170px;
    width: 17%;
    position: relative;

    .p-tabview-nav-content {
      .p-tabview-nav {
        display: flex;
        flex-direction: column;
        border: 0;

        li {
          border-bottom: 2px solid transparent;
          border-top: 2px solid transparent;

          .p-tabview-nav-link {
            border-radius: 0;
            margin: 0;
            border: 0 !important;
            color: var(--base-color);
            font-size: var(--md-fontsize);
            padding: 6px 16px;

            span,
            app-title {
              font-weight: normal;

              &.invalid {
                opacity: .5;
              }
            }

            app-title {
              display: flex;
              align-items: center;
              flex: 1;
              min-width: 0;

              span:first-child {
                flex: 1;
                min-width: 0;
                padding-right: 10px;
              }

              .measure-items {
                &:not(.measureStatus) {
                  span:first-child {
                    max-width: calc(100% - 25px);
                  }
                }
              }
            }

            &:not(.p-disabled):focus {
              box-shadow: none;
            }

            .icon-delete {
              padding-left: 5px;
              font-size: var(--md-fontsize);
              margin-left: auto;

              &:disabled {
                opacity: .4;
              }
            }

            .icon-error,
            .icon-check {
              padding-left: 6px;
              margin-left: auto;
            }
          }

          &.p-highlight {
            position: relative;
            z-index: 1;
            border-right: 0 !important;

            .p-tabview-nav-link {
              background-color: #F5F8F9;
              color: #D6002A;
              .chevron {
                background-color: #D6002A;
              }
            }
          }

          &.p-disabled {
            opacity: 0.4;
          }

          &.p-tabview-ink-bar {
            border: 0;
          }
        }
      }
    }
  }

  .p-tabview-panels {
    flex: 1;
    padding: var(--lg-space) var(--lg-space) var(--lg-space) 40px;
    border: none;
    min-width: 0;
    color: var(--base-color);

    p-tabpanel[ng-reflect-disabled="true"] {
      opacity: 0.4;
    }
  }
}