.search,
.search-list,
.table__header,
.billing-period,
.voyage__filter,
.asset-mobile-well tr {
  .mat-mdc-form-field-infix {
    min-height: 37px !important;
  }
  mat-datepicker-toggle {
    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 37px;
      height: 37px;
      padding: 7px;
      margin: 0;
    }
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-top: 10px;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 19px;
  }
  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-113%) scale(0.65);
  }
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 7px;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 8px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-26.75px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
  }
}
