.mat-mdc-dialog-title {
  &::before {
    display: none !important;
  }
  padding: 16px 16px 16px 24px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px solid #dddddd;

  .mat-icon {
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    cursor: pointer;
  }
}

mat-dialog-content {
  padding: 24px !important;
}

mat-dialog-actions {
  padding: 16px 24px !important;
  border-top: 1px solid #dddddd !important;
  button {
    ~ button {
      margin-left: 8px !important;
    }

    margin-right: 0 !important;
  }
}
