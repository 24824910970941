p-breadcrumb {
  nav {
    background-color: transparent;
    font-size: 16px;
    line-height: 20px;
    height: 20px;

    .p-menuitem-separator {
      margin: 0 3px;
    }
    .active {
      .p-menuitem-link {
        .p-menuitem-text {
          color: #00729e;
        }
      }
    }
  }
}
