.switch-select {
    background-color: var(--border-color);
    padding: 4px;
    border-radius: var(--md-border-radius);
    display: flex;
    gap: 8px;

    .p-ripple {
        padding: 2px 63px;
        border-radius: var(--md-border-radius);
        font-size: 13px;
        line-height: 20px;
        background-color: transparent;

        &.p-highlight {
            background-color: white;
            color: var(--base-color);
        }

        &:focus {
            box-shadow: none;
        }

        .p-button-label {
            font-weight: 400;
        }

    }
}

.order-processing-switch-select {
  background-color: var(--border-color);
  border-radius: var(--md-border-radius);
  border: 1px solid #1C1E41;
  display: flex;

  .p-ripple {
    padding: 13px 13px;
    font-size: 13px;
    line-height: 20px;

    &.p-highlight {
      background-color: #1C1E41;
      color: white;
    }

    &:focus {
      box-shadow: none;
    }

    .p-button-label {
      font-weight: 400;
    }

  }
}