p-card {
  display: block;
  .p-card {
    border: 1px solid var(--light-border-color);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: var(--base-color);

    .voyage-list & {
      height: 100%;
    }

    .p-card-title {
      font-weight: 700;
      font-size: var(--lg-fontsize);
      margin-bottom: var(--md-space);
    }

    .p-card-header {
      padding: var(--md-space);
    }

    .p-card-body {
      padding: var(--md-space);
      .p-card-content {
        padding: 0;
      }
    }
  }
}

