.p-toast-message-content {
  margin: 7px 0px 5px 10px !important;
  display: flex;
  align-items: center;
}

.p-toast-message-icon {
  padding-right: 12px !important;
  margin-top: 10px !important;
}
