p-accordion {
  .p-accordion {
    p-accordiontab {
      .p-accordion-tab {
        border: 0;
        border-top: 2px solid var(--border-color);

        .p-accordion-header {
          &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
              background-color: transparent;
            }
          }

          .btn-outline-secondary {
            margin-right: var(--lg-space);
          }

          a {
            &.p-accordion-header-link {
              font-weight: 700;
              font-size: var(--lg-fontsize);
              color: var(--base-color);
              min-height: 56px;
              background-color: transparent !important;
              border: 0;
              border-radius: var(--sm-border-radius);

              .pi {
                font-family: 'icomoon', sans-serif;
                font-size: 6px;

                &.pi-angle {
                  &-up {
                    &:before {
                      content: '\e919';
                    }
                  }

                  &-down {
                    &:before {
                      content: '\e91a';
                    }
                  }
                }
              }
            }
          }

          &:not(.p-disabled) .p-accordion-header-link:focus {
            box-shadow: none;
          }

          &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
          &:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
            background: var(--hover-color);
            border-color: var(--border-color);
            color: var(--base-color);
          }
        }

        .p-accordion-content {
          padding: 10px 0 0;
          border: 0;
          color: var(--base-color);
          position: relative;
        }

        h4 {
          margin-bottom: var(--md-space);
        }

        &:not(.p-accordion-tab-active) {
          .p-accordion-header {
            &.p-disabled {
              &:not(.p-highlight) {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
  &.accordion-card-style {
    p-accordiontab {
      .p-accordion-tab {
        background-color: white;
        margin-bottom: 10px;
        border-radius: 30px;
        border: 1px solid rgb(156, 156, 156);
        color: var(--base-color);
        padding: 20px 16px 20px 10px;

        .p-accordion-header {
          &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
              background-color: transparent !important;
            }
          }

          .btn-outline-secondary {
            margin-right: var(--lg-space);
          }

          a {
            &.p-accordion-header-link {
              font-weight: 700;
              font-size: var(--lg-fontsize);
              color: var(--base-color);
              min-height: 56px;
              background-color: transparent !important;
              border: 0;
              border-radius: var(--sm-border-radius);

              .pi {
                font-family: 'icomoon', sans-serif;
                font-size: 6px;

                &.pi-angle {
                  &-up {
                    &:before {
                      content: '\e919';
                    }
                  }

                  &-down {
                    &:before {
                      content: '\e91a';
                    }
                  }
                }
              }
            }
          }

          &:not(.p-disabled) .p-accordion-header-link:focus {
            box-shadow: none;
          }

          &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
          &:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
            background: var(--hover-color);
            border-color: var(--border-color);
            color: var(--base-color);
          }
        }

        .p-accordion-content {
          padding: 10px 0 0;
          border: 0;
          border-top: 0;
          color: var(--base-color);
          position: relative;
          background: transparent;
        }

        h4 {
          margin-bottom: var(--md-space);
        }

        &:not(.p-accordion-tab-active) {
          .p-accordion-header {
            &.p-disabled {
              &:not(.p-highlight) {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
  &.accardion-dropdown-style {
    p-accordiontab {
      .p-accordion-tab {
          border-top: none;
          box-shadow: none;
          margin-bottom: 0;
        .p-accordion-header {
          border: 1px solid var(--border-color);
          border-radius: var(--sm-border-radius);
          box-shadow: none;
          min-width: 350px;
          a.p-accordion-header-link {
            border-top: none;
            box-shadow: none;
            font-weight: 400;
            font-size: 16px;
            min-height: 30px;
            padding: 4px 16px;
            background-color: var(--white) !important;
            .p-icon-wrapper {
              .p-icon {
                height: 14px;
                width: 16px;
                transform: rotate(90deg);
                color: #6c757d;
              }
            }
          }
        }
        .p-accordion-content {
          background: transparent;
          padding: 0px;
        }
        &.p-accordion-tab-active {
          .p-accordion-header a.p-accordion-header-link .p-icon-wrapper .p-icon {
            transform: none;
          }
        }
        .p-toggable-content .p-accordion-content {
          padding: 0;
        }
      }
    }
  }
}
