.p-cardAccordion {
  display: block;
  .p-card {
    border: 1px solid rgb(156, 156, 156);
    box-shadow: none;
    border-radius: 30px;
    color: var(--base-color);

    .voyage-list & {
      height: 100%;
    }

    .p-card-title {
      font-weight: 700;
      font-size: var(--lg-fontsize);
      margin-bottom: var(--md-space);
    }

    .p-card-header {
      padding: var(--md-space);
    }
  }
}
