:root {
  --base-color: #08162a;
  --hover-secondary: #08164f;
  --primary: #d6002a;
  --hover-primary: #ad030f;
  --primary-2: #00855a;
  --primary-2-op1: rgba(0, 133, 90, 0.1);
  --primary-3: #004f95;
  --primary-3-op1: rgba(0, 79, 149, 0.1);
  --primary-4: #ffd400;
  --primary-5: #006ab9;
  --primary-6: #ffa754;
  --danger-color: #e30000;
  --white: #fff;
  --light-color: #787f90;
  --light-color-2: #eceef1;
  --border-color: #dddddd;
  --light-border-color: #e5e5e5;
  --light-dark-color: #EEEEEE;
  --border-focus-color: #787f90;
  --disabled-color: #b6bbc7;
  --placeholder-color: #787f90;
  --label-color: #737373;
  --gray-color: #999999;
  --dark-gray-color: #666666;
  --form-bg: #f4f5f7;
  --hover-color: #f4f5f7;
  --scroll-color: #c5c5c5;
  --scroll-track-color: #f1f1f1;
  --tab-focus-color: #fafafc;
  --blue-color-1: #008BCC;

  --font-family: 'Noto Sans', sans-serif;
  --box-shadow: 0px 1px 2px rgba(8, 22, 42, 0.15);
  --border-radius: 2px;
  --sm-border-radius: 6px;
  --md-border-radius: 8px;
  --lg-border-radius: 16px;

  --base-fontsize: 14px;
  --sm-fontsize: 12px;
  --md-fontsize: 16px;
  --fontsize: 18px;
  --lg-fontsize: 20px;
  --xl-fontsize: 24px;

  --lg-space: 24px;
  --md-space: 16px;
}
