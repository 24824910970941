.p-steps {
  .p-steps-item {

    .p-menuitem-link {
      cursor: pointer;
      margin-bottom: 10px;

      .p-steps-number {
        color: #465668;
        background: #fff;
        border: 1px solid #465668;
        margin-bottom: 5px;
      }
    }

    &::before {
      border-top: 1px dotted #A1AEBE;
      left: -48%;
    }

    &.p-highlight, &.passed {
      .p-menuitem-link {

        .p-steps-number {
          color: var(--primary);
          border-color: var(--primary);
        }

        .p-steps-title {
          font-weight: 400;
          color: var(--primary);
        }
      }
    }

    &.p-highlight {
      .p-menuitem-link {
        cursor: default;

        .p-steps-number {
          font-weight: 600;
        }

        .p-steps-title {
          font-weight: 600;
        }
      }
    }

    &.passed {
      &::before {
        border-top: 1px solid var(--primary);
      }
    }

    &:first-child {
      &::before {
        border-top: none;
      }
    }
  }
}
