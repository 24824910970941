.button-primary {
  @include btn();
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
.btn-outline-secondary {
  @include btn(
    $btn-bg: transparent,
    $border: 1px solid var(--border-color),
    $btn-text-color: var(--base-color)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
.btn-outline {
  @include btn(
    $btn-bg: transparent,
    $border: 1px solid var(--border-color),
    $btn-text-color: var(--base-color)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-secondary));
    }
  }
}

.btn-secondary {
  @include btn(
    $btn-bg: var(--base-color),
    $border: 1px solid var(--base-color),
    $btn-text-color: var(--white)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-secondary));
    }
  }
}
.btn-white {
  @include btn(
    $btn-bg: var(--white),
    $border: 1px solid var(--border-color),
    $btn-text-color: var(--base-color)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}

.btn-text-white {
  @include btn(
    $btn-bg: transparent,
    $border: none,
    $btn-text-color: var(--white)
  );
  &:enabled {
    &:hover,
    &:active {
      background-color: transparent;
      color: var(--light-color);
      border-color: transparent;

      em {
        background: var(--light-color);
      }
    }
  }

  em {
    background: var(--white);
  }
}

.btn-icon {
  cursor: pointer;
  background-color: transparent;
  &.icon-remove,
  &.icon-delete {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: var(--hover-primary);
        }
      }
    }
  }
  &.icon-save {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: #2b8fe9;
        }
      }
    }
  }
  &.icon-edit {
    &:not(:disabled) {
      &:hover {
        &:before {
          color: var(--primary-5) !important;
        }
      }
    }
  }
}


.btn-text {
  @include btn(
    $btn-bg: transparent,
    $border: none,
    $btn-text-color: var(--base-color)
  );
  &:enabled {
    &:hover,
    &:active {
      background-color: transparent;
      color: var(--light-color);
      border-color: transparent;

      em {
        background: var(--light-color);
      }
    }
  }

  em {
    background: var(--white);
  }
}

.btn-icon-only {
  @include btn(
    $btn-bg: transparent,
    $border: none,
    $btn-text-color: var(--base-color)
  );
  padding: 0;
  min-width: 0;
  &:enabled {
    &:hover,
    &:active {
      background-color: transparent;
      color: var(--light-color);
      border-color: transparent;

      em {
        background: none;
      }
    }
  }
}

.btn-primary-2 {
  @include btn(
    $btn-bg: var(--primary-2),
    $border: 1px solid var(--primary-2),
    $btn-text-color: var(--white)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}

.btn-primary-2-outline {
  @include btn(
    $btn-bg: var(--white),
    $border: 1px solid var(--primary-2),
    $btn-text-color: var(--primary-2)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--primary-2));
    }
  }
}

.btn-primary-5 {
  @include btn(
    $btn-bg: var(--primary-5),
    $border: 1px solid var(--primary-5),
    $btn-text-color: var(--white)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}

.btn-primary-6 {
  @include btn(
    $btn-bg: var(--primary-6),
    $border: 1px solid var(--primary-6),
    $btn-text-color: var(--white)
  );
  &:enabled {
    &:hover,
    &:active {
      @include hover-styles(var(--hover-primary));
    }
  }
}
