.color-picker-select {
  gap: 8px;
  display: flex;
  .p-button {
    border-radius: 4px;

    .item {
      width: 32px;
      height: 32px;
    }

    &.p-highlight {
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
    }
  }
}
