ngx-mat-datetime-content {
  margin: 16px 0 0 -16px;
  .mat-calendar-body-cell {
    height: auto !important;
    position: relative !important;
    left: auto !important;
    top: auto !important;
  }
}
button.mat-calendar-body-cell {
  height: 100% !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}
.mat-mdc-form-field-icon-suffix {
  min-width: 40px;
}
mat-datepicker-toggle {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}
lha-date-range,
.form__box--date {
  .mat-mdc-form-field-icon-suffix {
    min-width: 80px;
    display: flex;
    justify-content: flex-end;
  }
  mat-datepicker-toggle {
    width: calc(100% - 33px);
    margin-right: 33px;
    &.toggle-clear {
      width: 37px;
      margin: 0;
      position: static;
    }
  }
}
