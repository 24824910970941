.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  //opacity: 0 !important;
}
.mat-mdc-form-field-focus-overlay {
  //background: transparent !important;
}
.mat-mdc-form-field-infix {
  min-height: 51px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  //background: none !important;
  //border: 1px solid rgba(0, 0, 0, 0.42);
  //border-bottom: none;
  border-radius: 6px;
}
.custom_dialog,
.form__block {
  .mat-mdc-form-field {
    width: 100%;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-31.75px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    min-height: 51px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    align-items: center;
  }
}
.login-form {
  .mat-mdc-form-field-infix {
    width: 100%;
  }
}
lha-autocomplete-select {
  width: 100%;
}
.vessel__form {
  .mdc-text-field--disabled .mdc-text-field__input {
    color: var(--theme-primary);
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.38);
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:disabled
    .mdc-switch__icons {
    opacity: 1;
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:disabled
    .mdc-switch__icon {
    fill: var(--theme-white);
  }
  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
    background: var(--theme-primary, var(--mdc-theme-surface, #fff));
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected
    .mdc-switch__handle::before {
    background: var(
      --mdc-switch-disabled-unselected-icon-color,
      var(--mdc-theme-surface, #fff)
    );
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:disabled
    .mdc-switch__icon {
    fill: var(
      --mdc-switch-disabled-unselected-icon-color,
      var(--mdc-theme-on-primary, #fff)
    );
  }
}
.billing-period {
  .mat-mdc-form-field-infix {
    width: auto;
  }
}

@media (max-width: 900px) {
  lha-date-range {
    .mat-mdc-form-field-infix {
      width: 150px;
    }
  }
}
