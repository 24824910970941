.custom-dropdown-style {
  display: flex;
  .p-inputtext {
    border: none;
    padding-right: 35px;
  }
  .p-autocomplete-dropdown {
    padding: 0;
    background: transparent;
    border: none;

    svg {
      font-size: var(--sm-fontsize);
      color: var(--light-color);
      height: 14px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  timesicon {
    svg {
      height: 14px;
      width: 14px;
    }
  }
}
