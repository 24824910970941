
.p-float-label label {
  top: 32%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--base-fontsize);
}

.p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  top: -8px;
  font-size: var(--sm-fontsize);

}

.p-float-label :focus ~ label, .p-float-label .p-filled ~ label {
  top: -8px;
  font-size: var(--sm-fontsize);

}