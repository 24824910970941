.attachment-style {
  .p-fileupload-row {
    padding-top: 20px;
    display: flex;
    text-align: center;
    align-items: center;
  }

  .p-fileupload-content {
    display: none;
  }

  .p-fileupload-content .p-progressbar {
    top: 10px !important;
  }

  .p-fileupload-buttonbar {
    background-color: transparent;
    p-button {
      display: none;
    }
  }
  .p-progressbar-determinate {
    display: none;
  }

  .p-fileupload-choose {
    background-color: #d6082a;
    margin-right: 8px;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .p-button-icon-only {
    background-color: transparent;
    padding: 0px !important;
    width: 40px;
    height: 20px;
    border: none;
    color: black;
  }
}
