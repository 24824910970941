p-sidebar {
  .p-sidebar {
    background: rgb(247, 247, 247);
  }
  .p-sidebar-header {
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
  }
  .p-sidebar-content {
    padding: 16px;
  }

  .p-sidebar-footer {
    border-top: 1px solid var(--border-color);
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
