p-menu {
  .p-menu-list {
    box-shadow: 0px 6px 8px -2px #2222221a;
    .p-menuitem {
      .p-menuitem-content {
        border: 0;
        padding: 8px 16px;

        a {
          display: flex;
          gap: 8px;
        }
      }
    }
  }
}
