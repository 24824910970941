.snackbar {
  &--error {
    .mdc-snackbar__surface {
      background-color: var(--theme-warn) !important;
    }
  }
  &--success {
    .mdc-snackbar__surface {
      background-color: var(--theme-green) !important;
    }
  }
}
.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: var(--theme-white) !important;
}
.mat-mdc-snack-bar-label {
  word-break: break-all;
}
