p-dropdown {
  position: relative;

  .new-version {
    border: 1px solid var(--border-color);
    border-radius: var(--sm-border-radius);
    width: 100%;
    background: var(--white);
    transition: none;
    position: static;
    line-height: 22px;

    .p-dropdown-label {
      padding: 4px 16px;
      font-size: var(--md-fontsize);
      color: var(--base-color);

      &.p-placeholder {
        color: var(--placeholder-color);
      }
    }

    &:not(.p-disabled) {
      &.p-focus {
        border-color: var(--border-focus-color);
        box-shadow: none;
        &:hover {
          border-color: var(--border-focus-color);
        }
      }

      &:hover {
        border-color: var(--border-color);
      }
    }

    .p-dropdown-trigger-icon {
      font-size: var(--sm-fontsize);
      color: var(--light-color);
      height: 14px;
    }

    .p-dropdown-clear-icon {
      width: 12px;
      height: 12px;
      color: var(--light-color);
      margin-top: -0.5rem;
    }

    &.p-disabled {
      border-color: var(--form-bg);

      .p-dropdown-label {
        color: var(--disabled-color);

        &.p-placeholder {
          color: transparent;

          ~ .p-dropdown-trigger {
            display: none;
          }
        }
      }

      .p-dropdown-trigger-icon {
        color: var(--disabled-color);
      }
    }
  }

  &.ng-invalid.ng-touched {
    .p-dropdown {
      border-color: var(--danger-color) !important;

      .p-dropdown-filter-container {
        .p-dropdown-filter {
          border-color: var(--border-color) !important;
        }
      }
    }

    &:after {
      right: 34px;
    }
  }

  .p-overlay {
    max-width: 100%;
  }

  &.link {
    .p-dropdown .p-dropdown-label {
      @include link;
    }

    &:hover {
      .p-dropdown .p-dropdown-label {
        color: var(--hover-primary);
      }
    }
  }
}

.new-version {
  &.viewMode {
    border: 0;
    background-color: transparent;
    pointer-events: none;
    user-select: auto;

    .p-dropdown-label {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
      padding: 0;
    }

    .p-dropdown-trigger,
    .p-icon-wrapper {
      display: none;
    }

    .p-dropdown-label.p-placeholder {
      display: none;
    }

    &.p-disabled {
      .p-dropdown-label {
        color: var(--base-color);
      }

      .p-dropdown-trigger-icon {
        color: var(--base-color);
      }
    }
  }
}

.p-overlay-content {
  margin: 3px 0;

  .new-version-panel {
    box-shadow: none;
    border: 2px solid var(--border-color);
    border-radius: var(--md-border-radius);
    overflow: hidden;

    .p-dropdown-items-wrapper {
      border-radius: var(--md-border-radius);
    }

    .p-dropdown-header {
      border-top-right-radius: var(--md-border-radius);
      border-top-left-radius: var(--md-border-radius);
      padding: 6px 16px 0;
      border-bottom: 0;
      background-color: transparent;
    }

    .p-dropdown-items {
      padding: 8px 0;

      .p-dropdown-item {
        color: var(--base-color);
        padding: 7px var(--md-space);
        white-space: normal;

        &:has(.optionDisabled) {
          opacity: 0.5;
          pointer-events: none;
        }

        &.p-disabled {
          opacity: 0.5;
        }

        &:not(.p-highlight):not(.p-disabled).p-focus {
          background-color: var(--tab-focus-color);
        }

        &:not(.p-highlight):not(.p-disabled):hover,
        &.p-highlight,
        &.p-highlight.p-focus {
          background-color: var(--hover-color);
        }
      }

      .p-dropdown-item-group {
        padding: 6px var(--md-space);
        color: var(--base-color);

        ~ p-dropdownitem {
          .p-dropdown-item {
            padding: 6px var(--lg-space);
          }
        }
      }
    }
  }

  .p-dropdown-empty-message {
    color: var(--light-color);
    padding: 7px var(--md-space);
  }
}

@media only screen and (max-width: 767px) {
  .viewMode {
    .p-dropdown {
      .p-dropdown-label {
        padding-left: 0;
      }
    }
  }
}
