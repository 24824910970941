.horizontal-tab {
  .p-tabview-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    li {
      border-bottom: 1px solid var(--border-color);
      border-top: 2px solid transparent;
      padding: 0px 10px;
      width: 100%;

      .p-tabview-nav-link {
        border-radius: 0;
        margin: 0;
        color: var(--base-color);
        font-size: var(--md-fontsize);
        padding: 9px 16px;
        display: flex;
        justify-content: center;
        font-weight: 400;
        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }

      &.p-highlight {
        position: relative;
        z-index: 1;
        border-right: 0 !important;
        border-bottom: 2px solid var(--primary);

        .p-tabview-nav-link {
          background-color: transparent;
          color: var(--primary);
          font-weight: 700;
          .chevron {
            background-color: var(--primary);
          }
        }
      }

      &.p-disabled {
        opacity: 0.4;
      }

      &.p-tabview-ink-bar {
        border: 0;
      }
    }
  }
}
