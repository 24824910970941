.p-inputswitch {
  .p-inputswitch-slider {
    background-color: var(--primary);
    box-shadow: none;
    outline: none;
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background-color: #444661;
    }
  }
}
