p-autoComplete {
  position: relative;

  .p-autocomplete {
    border: 1px solid var(--border-color);
    border-radius: var(--sm-border-radius);
    width: 100%;
    background: var(--white);
    transition: none;
    position: static;
    line-height: 22px;

    .p-autocomplete-input {
      &.p-placeholder {
        color: var(--placeholder-color);
      }
    }

    &:not(.p-disabled) {
      &.p-focus {
        border-color: var(--border-focus-color);
        box-shadow: none;

        .p-autocomplete-multiple-container {
          box-shadow: none;
        }
        &:hover {
          border-color: var(--border-focus-color);
        }
      }

      &:hover {
        border-color: var(--border-color);
      }
    }

    &.p-disabled {
      border-color: var(--form-bg);

      .p-autocomplete-input {
        color: var(--disabled-color);

        &.p-placeholder {
          color: transparent;
        }
      }
    }
  }

  .p-overlay {
    max-width: 100%;
  }

  + app-show-control-errors {
    display: none;
  }
}

.p-autocomplete {
  &.viewMode {
    border: 0;
    background-color: transparent;
    pointer-events: none;
    user-select: auto;

    .p-autocomplete-input {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
      padding: 0;
    }
    .p-icon-wrapper {
      display: none;
    }

    .p-autocomplete-input.p-placeholder {
      display: none;
    }

    &.p-disabled {
      .p-autocomplete-input {
        color: var(--base-color);
      }
    }
  }
}

.viewMode {
  .p-autocomplete {
    border-color: transparent !important;
    background-color: transparent;
    pointer-events: none;
    padding: 0;
    @include placeholder-color(transparent);
  }
}

.p-overlay-content {
  margin: 3px 0;

  .p-autocomplete-panel {
    .p-autocomplete-items {
      .p-autocomplete-item {
        color: var(--base-color);
        padding: 7px var(--md-space);
        white-space: normal;

        &:has(.optionDisabled) {
          opacity: 0.5;
          pointer-events: none;
        }

        &.p-disabled {
          opacity: 0.5;
        }

        &:not(.p-highlight):not(.p-disabled).p-focus {
          background-color: var(--tab-focus-color);
        }

        &:not(.p-highlight):not(.p-disabled):hover,
        &.p-highlight,
        &.p-highlight.p-focus {
          background-color: var(--hover-color);
        }
      }


    }
  }
}
